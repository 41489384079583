<template>
  <div style="border: 1px solid #ccc">
    <Toolbar
      style="border-bottom:1px solid #ccc"
      :editor="editor"
      :defaultConfig="toolbarConfig"
    />
    <!-- 编辑器 -->
    <Editor
      style="height:400px; overflow-y:hidden"
      :defaultConfig="editorConfig"
      v-model="html"
      @onChange="onChange"
      @onCreated="onCreated"
      @onInsertedImage="InsertImage"
    />
  </div>
</template>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<script>

import '@wangeditor/editor/dist/css/style.css'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue';


export default {
  name: 'EtaxWebV2WangEditor',
  components: { Editor, Toolbar },

  props:{
    html:{
      default:'',
    }
  },
  data() {
    return {
      editor: null,
      toolbarConfig: { },
      editorConfig: { MENU_CONF: {} },
      mode: 'default', // or 'simple'
    };
  },
  created(){
      this.editorConfig.MENU_CONF['uploadImage'] = {
      customInsert(res, insertFn) {                  // JS 语法
        // res 即服务端的返回结果
        // 从 res 中找到 url alt href ，然后插入图片
        insertFn('https://file.listensoft.net' +res.data.url,"xx","xx")
      },
        fieldName: 'upFile',
        server:'https://public.listensoft.net/api/uploadImg',
      }
  },
  mounted() {
    
  },

  methods: {
    onChange(){
      this.$emit('update:html', this.html)
      // console.log(this.html.indexOf('<img'));
      // console.log(this.insertStr(this.html,7,''+' style="width:200px;height:200px"'));
    },
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
      // this.editor.setHtml(this.insertStr(this.html,7,''+' style="width:200px;height:200px"'));
    },
    // insertStr(source, start, newStr) {
    //   return source.slice(0, start) + newStr + source.slice(start)
    // }

  },
};
</script>
