<template>
  <div class="app-container">

    <div style="margin-bottom:10px">
      <el-button type="primary" @click="add()" size="small">新增</el-button>
    </div>


    <el-table :height="contentStyleObj" :data="list" border style="width: 100%">
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column prop="title" label="大标题" width="200" align="center">
      </el-table-column>
      <el-table-column prop="name" label="大标题" width="200" align="center">
      </el-table-column>
      <el-table-column prop="content" min-width="200px" label="内容" align="center">
        <template #default="scope">
          <span class="overflow" v-html="scope.row.content"></span>
        </template>
      </el-table-column>

      <el-table-column width="140px" label="操作" align="center">
        <template #default="scope">
          <el-button type="success" size="small" @click="save(scope.row)" plain>编辑</el-button>
          <el-button type="primary" size="small" @click="dels(scope.row.id)" plain>删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" />
    </div>

    <el-dialog :close-on-click-modal="false" destroy-on-close title="编辑" v-model="dialogFormVisible">
      <el-form>

        <el-form-item label="分类" :label-width="formLabelWidth">
            <el-radio-group v-model="form.type">
              <el-radio :label="1">系统消息</el-radio>
              <el-radio :label="2">下载</el-radio>
            </el-radio-group>
        </el-form-item>
        <el-form-item label="是否弹窗" :label-width="formLabelWidth">
            <el-radio-group v-model="form.elastic">
              <el-radio :label="2">弹</el-radio>
              <el-radio :label="1">不弹</el-radio>
            </el-radio-group>
        </el-form-item>

        <el-form-item label="用户类型" :label-width="formLabelWidth">
          <el-checkbox-group v-model="form.show">
            <el-checkbox label="">全部</el-checkbox>
            <el-checkbox label="1">正常</el-checkbox>
            <el-checkbox label="2">试用</el-checkbox>
            <el-checkbox label="3">活动账号</el-checkbox>
          </el-checkbox-group>
        </el-form-item>


        <el-form-item label="到期日期" :label-width="formLabelWidth">
          <el-date-picker size="small" style="width:25%;" v-model="form.endTime" type="date" placeholder="到期日期" value-format="YYYY-MM-DD"></el-date-picker>
        </el-form-item>

        <el-form-item label="大标题" :label-width="formLabelWidth">
          <el-input v-model="form.title" size="small" style="width:96%"></el-input>
        </el-form-item>

        <el-form-item label="小标题" :label-width="formLabelWidth">
          <el-input v-model="form.name" size="small" style="width:96%"></el-input>
        </el-form-item>

        <el-form-item label="活动区域" :label-width="formLabelWidth">
          <!-- <vWangeditor ref="text" v-model="form.content" class="myQuillEditor" :options="editorOption" style="width:96%"/> -->
          <wangEditor v-model:html="form.content"></wangEditor>
          <!-- <vWangeditor @change="changeContent" ref="text" :content="form.content" class="myQuillEditor" :options="editorOption"></vWangeditor> -->
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogFormVisible = false" size="small">取 消</el-button>
          <el-button type="primary" @click="submit" size="small">确 定</el-button>
        </div>
      </template>
      
    </el-dialog>
  </div>
</template>

<script>

import { updateList,updateSave,updateDel, updateListOne } from '@/api/org.js'
// import { vWangeditor } from 'vue3-wangeditor'
import  wangEditor  from "./components/wangEditor.vue"
// 
export default {
  name: 'update',
  // components: { vWangeditor},
  components: { wangEditor},

  data() {
    return {
      dialogFormVisible: false,
      listQuery: {
        page: 1,
        limit: 20
      },
      total: 0,
      list: [],
      listLoading: true,
      formLabelWidth:'100px',
      form:{
        title:'',
        name: '',
        content: '',
        type: 1,
        elastic: 2,
        show:[]
      },
      editorOption: {},
      contentStyleObj:{}, //高度变化
    }
  },
  created() {
    this.getList()
  // 获取表格高度
    this.contentStyleObj=this.$getHeight(220)
    window.addEventListener('resize', this.getHeight);
  },
  methods: {
    dels(id){
      
      updateDel({id:id}).then(res=>{
        if(res.data.msg == "success") {
          this.$message({
            message: '删除成功',
            type: 'success'
          });
          this.getList()
          this.dialogFormVisible = false
        }
      })
    },
    save(row){
      // this.form = Object.assign({}, row)
      updateListOne({id:row.id}).then(res=>{
        this.form = res.data.data.info;
        this.form.show = this.form.show.split(',')
        this.dialogFormVisible = true
      })
      
    },
    add(){
      this.form.id = 0
      this.form.title = ""
      this.form.name = ""
      this.form.content = "请输入..."
      this.dialogFormVisible = true
    },
    changeContent(e){
      this.form.content = e.html
    },
    submit () {
      if(!this.form.title && !this.form.name && !this.form.content){
        this.$message.error('请补充完整信息');
        return
      }
      this.form.show = this.form.show.join(',')
      updateSave(this.form).then(res=>{
        if(res.data.msg == "success") {
          this.$message({
            message: '新增成功',
            type: 'success'
          });
          this.getList()
          this.dialogFormVisible = false
        }
      })
    },
    getList() {
      this.listLoading = true
      updateList(this.listQuery).then(response => {
        this.list = response.data.data.list
        this.total = response.data.data.total
        this.listLoading = false
      })
    },
    handlemyclass(row, column, rowIndex, columnIndex){
      console.log(row, column, rowIndex, columnIndex,111111111111111111);
      return 'overflow'
    }
  }
}
</script>
<style>
.overflow{
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
</style>